<template>
  <section class="hero">
    <div class="hero-background"></div>
    <div class="hero-content container">
      <h1 class="mb-0 gradient-title">GOLDEN GARDEN</h1>
      <p>Asociación Civil Argentina de Cultivo de Cannabis.</p>
      <a href="#contacto">
        <button class="btn btn-primary px-3 py-2">Inscripciones
          <i class="fas fa-arrow-right ms-2">
          </i>
        </button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroComponent'
}
</script>

<style scoped>
.hero {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('@/assets/images/hero-image.png');
  background-size: cover;
  background-position: center;
  animation: heroZoomOut 10s ease-out forwards infinite;
}

.hero-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 100%);
}
/* gradient-title */
@media (max-width: 768px) {
  .gradient-title {
    font-size:3rem;
  }
}

@media (max-width: 440px) {
  .gradient-title {
    font-size: 4rem !important;
  }
}

@keyframes heroZoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.hero-content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
}

.hero-content h1 {
  font-size: 6rem;
}

.hero-content p {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 6rem;
  }
  .hero-content p {
    font-size: 1.3rem;
  }
}

@media (max-width: 300px) {
  .hero-content h1 {
    font-size: 3.4rem !important;
  }
  .hero-content p {
    font-size: 1.2rem;
  }
}
</style>

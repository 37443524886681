<template>
  <div id="app">
    <Navbar/>
    <router-view/>
    <a href="https://wa.me/TUNUMERO" target="_blank" class="whatsapp-float" title="Contactanos por WhatsApp">
      <i class="fab fa-whatsapp"></i>
      <span class="tooltip">¿Necesitas ayuda?</span>
    </a>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
:root {
  --primary: #FFA500; /* Reemplazar con tu color primario real */
  /* ...other variables... */
}

@import '@/assets/css/main.css';
@font-face {
  font-family: 'ClashDisplay';
  src: url('@/assets/fonts/ClashDisplay-Variable.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'ClashDisplay', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0; /* Remove margin that conflicts with navbar */
}

.whatsapp-float {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease;
}

.whatsapp-float:hover {
  transform: scale(1.1);
  color: white;
}

.whatsapp-float .tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  right: 80px; /* Aumentado para separarlo más del botón */
  font-size: 14px;
  white-space: nowrap;
  transition: opacity 0.3s, visibility 0.3s;
}

.whatsapp-float:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>

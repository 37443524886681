<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" :class="{ 'navbar-scrolled': isScrolled || isMenuOpen }">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img src="/logo_color.png" alt="Golden Garden" height="50">
      </a>
      
      <button class="hamburger-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" 
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
              @click="toggleMenu">
        <div class="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" v-for="(item, index) in menuItems" :key="index">
            <a class="nav-link nav-link-text" :href="item.href" @click="handleNavClick">{{ item.text }}</a>
          </li>
          <li class="nav-item ms-lg-4">
            <a class="nav-link btn btn-primary px-3" href="#contacto" @click.prevent="handleContactClick">
              Contacto
              <i class="fas fa-envelope ms-2"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarComponent',
  data() {
    return {
      isScrolled: false,
      isMenuOpen: false,
      menuItems: [
        { href: '/#nosotros', text: 'Nosotros' },
        { href: '/productos', text: 'Medicann' },
        { href: '/#normativas', text: 'Normativas' },
        { href: '/#faq', text: 'FAQ' }
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 200;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleNavClick(e) {
      if (e && e.target && e.target.href && e.target.href.includes('#')) {
        e.preventDefault();
        const id = e.target.href.split('#')[1];
        this.smoothScroll(id);
      }
      
      if (window.innerWidth < 992) {
        this.isMenuOpen = false;
        const navbarCollapse = document.getElementById('navbarNav');
        if (navbarCollapse.classList.contains('show')) {
          navbarCollapse.classList.remove('show');
        }
      }
    },
    smoothScroll(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        window.history.pushState(null, '', '#' + id);
      }
    },
    handleContactClick() {
      this.handleNavClick();
      this.smoothScroll('contacto');
    },
    scrollToContact(e) {
      e.preventDefault();
      const element = document.querySelector('#contacto');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        window.history.pushState(null, '', '#contacto');
      }
    }
  }
}
</script>

<style scoped>
.navbar {
  background-color: transparent;
  transition: all 0.3s ease;
  box-shadow: none;
  padding: 12px 0;
}

.navbar-scrolled {
  background-color: #000000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.navbar-brand {
  font-weight: bold;
  color: var(--primary);
}

.nav-link-text {
  color: #ffffff !important;
  margin: 0 10px;
  font-weight: 300;
  transition: color 0.3s ease;
}

.nav-link-text:hover {
  color: var(--primary) !important;
}

.router-link-active {
  color: var(--primary) !important;
  border-bottom: 2px solid var(--primary);
}

.contact-btn:hover {
  background-color: var(--primary-dark);
  color: white !important;
}

.hamburger-btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: none;
}

.hamburger {
  width: 30px;
  height: 20px;
  position: relative;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  transition: .25s ease-in-out;
}

.hamburger span:nth-child(1) { top: 0px; }
.hamburger span:nth-child(2) { top: 9px; }
.hamburger span:nth-child(3) { top: 18px; }

/* Animation for open state */
.hamburger-btn[aria-expanded="true"] .hamburger span:nth-child(1) {
  transform: rotate(45deg);
  top: 9px;
}

.hamburger-btn[aria-expanded="true"] .hamburger span:nth-child(2) {
  opacity: 0;
  width: 0;
}

.hamburger-btn[aria-expanded="true"] .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  top: 9px;
}

@media (max-width: 992px) {
  .hamburger-btn {
    display: block;
  }
  .contact-btn {
    margin: 10px 0;
    display: inline-block;
  }
}

.nav-link.btn-primary {
  color: #fff !important;
  background: var(--primary-dark);
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.nav-link.btn-primary:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.nav-link.btn-primary:active {
  transform: translateY(0);
}

.nav-link.btn-primary::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: inherit;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
}

.nav-link.btn-primary:active::after {
  transform: translate(-50%, -50%) scale(2);
  opacity: 0;
  transition: 0s;
}

.nav-link.btn-primary i {
  color: #fff;
  position: relative;
  z-index: 1;
}
</style>
